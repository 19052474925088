import * as React from "react"
import { Helmet } from "react-helmet";
import { Link } from "gatsby";

import { Navigation, Content } from "../components";
import "../styles/main.scss";

// Hook
const hook = <div className="row py-4">
    <div className="col-12 col-lg-6 d-flex align-items-center">
        <div>
            <h1>Sorry...</h1>
            <p>We don't have that page.</p>
        </div>
    </div>
</div>


// Action
const action = <>
    <div className="row py-4">
        <div className="col-12 col-lg-8 d-flex align-items-center">
            <div>
                <h2>Work with the data your already have</h2>
                <p>For a web page we already have, please return to our home page or get in touch.</p>
                <p><Link to="/">Home</Link></p>
                <p><Link to="/contact-us/">Contact us</Link></p>
            </div>
        </div>
    </div>
</>;

//

const NotFound = () =>
    <>
        <Helmet title="Qebal People Analytics" htmlAttributes={{ lang: 'en' }} />
        <Navigation />
        <Content>
            <div className="container-lg">
                {hook}
                {action}
            </div>
        </Content>
    </>;

export default NotFound;